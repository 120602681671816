import { parse, stringify } from 'querystringify'

function getLinkUrl() {
  const domain = window.location.hostname || window.location.host
  let path = window.location.pathname.replace(/\/index\.html.*/gi, '')
  if (path[0] === '/') path = path.slice(1)

  const params = (parse(window.location.search.slice(1)) as any) || {}
  const { refer_id, id, p, project  } = params
  // 一定要 encodeURIComponent！！！ 不然params 后面的内容会与params 同级解析
  const restParams = encodeURIComponent(stringify(params))
  const isDistribution = p === 'd' || project === 'distribution'
  const serverHOST = isDistribution ? window.__pwa__host_server_dp : window.__pwa__host_server

  return `${serverHOST}/pwa2/?domain=${domain}&path=${path}&pwa_path=${path}${refer_id ? `&refer_id=${refer_id || id}` : ''}${restParams ? `&params=${restParams}` : ''}`
}
function main() {
  if (!window.__dp__pwa__succed__cb) window.__dp__pwa__succed__cb = new Set()

  const link: HTMLLinkElement | null = document.querySelector('#web-manifest')
  if (!link) return

  link.href = getLinkUrl()

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js', { scope: './' })
  }

  window.addEventListener('beforeinstallprompt', function (event) {
    event.preventDefault()
    window.__dp__pwa__intall__evt = event
    if (window.__dp__pwa__succed__cb) {
      window.__dp__pwa__succed__cb.forEach(fun => fun())
    }
  })
}

main()
